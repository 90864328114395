
import UAParser from 'ua-parser-js';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SharingInstructionsViews',
    data: () => ({
        userAgent: null as null | UAParser.IResult,
    }),

    props: {
        isSSPReceiver: {
            type: Boolean,
            default: false
        },
        isSupportedBrowser: {
            type: Boolean,
            default: false
        },
        // This is based on the user
        // agent OS name in Connect.vue
        loadWasm: {
            type: Boolean,
            default: false
        },
    },

    components: {
    },

    computed: {
        getInstallLink() {
            console.log('UA', this.userAgent);
            if(!this.userAgent || !this.userAgent.os || !this.userAgent.os.name) return 'https://www.airsquirrels.com/ditto/download';

            if(this.userAgent.os.name === 'Mac OS') return 'https://www.airsquirrels.com/ditto/download/connect/macos';

            if(!this.userAgent.cpu || !this.userAgent.cpu.architecture) return 'https://www.airsquirrels.com/ditto/download';

            if(this.userAgent.os.name === 'Windows') {
                if(this.userAgent.cpu.architecture.toLowerCase().includes('64')) {
                    return 'https://www.airsquirrels.com/ditto/download/connect/windows/64';
                }
                return 'https://www.airsquirrels.com/ditto/download/connect/windows/32';
            } 

            return 'https://www.airsquirrels.com/ditto/download';
        },
    },

    methods: {
        continueOnBrowserClickHandler() {
            if(!this.isSupportedBrowser) return;
            this.$emit('continueOnBrowser');
        },

        continueToDownloadClickHandler() {
            this.$emit('continueToDownload');
        }
    },
    created() {
        const parser = new UAParser();
        this.userAgent = parser.getResult();
    }
});
